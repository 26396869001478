import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StatusComponent } from './status.component';
import { extract } from '@app/i18n';

const routes: Routes = [{ path: '', component: StatusComponent, data: { title: extract('Status') } }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProfileRoutingModule {}
