import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export enum STATUS {
  STABLE = 'stable',
  DOWN = 'down',
  INTER = 'intermittent',
}

export interface Status {
  name: string;
  status: string;
}

@Injectable({
  providedIn: 'root',
})
export class StatusService {
  private _statuses = new BehaviorSubject<Status[]>([]);

  constructor() {
    const $statuses: Status[] = [
      {
        //TMP
        name: 'AIP',
        status: STATUS.STABLE,
      },
      {
        name: 'Payment Portal',
        status: STATUS.INTER,
      },
      {
        name: 'Insured Portal',
        status: STATUS.STABLE,
      },
      {
        name: 'Majesco',
        status: STATUS.DOWN,
      },
    ];
    setTimeout(() => {
      console.log('updating statuses');
      this.updateStatuses($statuses); //get from API
    }, 300);
  }

  get statuses() {
    return this._statuses;
  }

  updateStatuses(statuses: Status[]) {
    this._statuses.next(statuses);
  }
}
