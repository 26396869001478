import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { StatusService, STATUS, Status } from './status.service';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss'],
})
export class StatusComponent implements OnInit {
  statuses: Status[];

  constructor(private statusService: StatusService) {}

  ngOnInit(): void {
    this.statusService.statuses.subscribe((statuses: Status[]) => (this.statuses = statuses));
  }
}
