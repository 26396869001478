import { Component, Input, OnInit } from '@angular/core';
import { Status } from '../status.service';
import { faCheckCircle, faExclamationCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'status-record',
  templateUrl: './status-record.component.html',
  styleUrls: ['./status-record.component.scss'],
})
export class StatusRecordComponent implements OnInit {
  @Input() status: Status;

  icons = {
    stable: faCheckCircle,
    intermittent: faExclamationCircle,
    down: faTimesCircle,
  };

  constructor() {}

  ngOnInit(): void {}
}
