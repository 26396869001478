import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StatusComponent } from './status.component';

import { ProfileRoutingModule } from './status-routing.module';
import { CoreModule, FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  AptButtonModule,
  AptFormModule,
  AptLayoutModule,
  AptTypographyModule,
  AptTemplatesModule,
  AptCardsModule,
  AptAuthSyncModule,
  AptMaintenanceModule,
  AptInformModule,
} from '@archinsurance-da/arch-portal-tools';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@progress/kendo-angular-dialog';
import { StatusRecordComponent } from './status-record/status-record.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [StatusComponent, StatusRecordComponent],
  imports: [
    CommonModule,
    TranslateModule,
    CoreModule,
    ProfileRoutingModule,
    // SharedModule,
    FormsModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    AptButtonModule,
    AptFormModule,
    AptLayoutModule,
    AptInformModule,
    AptTypographyModule,
    AptTemplatesModule,
    AptCardsModule,
    AptMaintenanceModule,
    AptAuthSyncModule,
  ],
})
export class StatusModule {}
