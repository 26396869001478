import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiEndpointsService } from '@core/http/api-endpoints.service';
import { ApiHttpService } from '@core/http/api-http.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { constants } from './../@shared/constants';
import { AuthenticationService } from '@app/authentication/authentication.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class HomeService {
  envName: string = environment.environmentName;
  idToken: string = localStorage.getItem('id_token').toString();

  constructor(
    private apiEndPoint: ApiEndpointsService,
    private apiHttpService: ApiHttpService,
    private authenticationService: AuthenticationService,
    private http: HttpClient
  ) {}

  getRolesAndPortals(): Observable<any> {
    return this.apiHttpService.post(this.apiEndPoint.createUrl(constants.permissionEndPoint), {}).pipe(
      map((result) => {
        return result;
      })
    );
  }

  decodeToken(idToken: string) {
    const helper = new JwtHelperService();
    let decodedJWT = helper.decodeToken(idToken);
    if (decodedJWT) {
      if (decodedJWT.hasOwnProperty('http://www.archinsurance.com/claims/roles')) {
        //decodedJWT['http://www.archinsurance.com/claims/roles'] = environment.mockRoleData;
        return decodedJWT['http://www.archinsurance.com/claims/roles'];
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  /**
   * Check each portal access depends on AUth0 role
   * Check against mapped array
   * @param portals
   */
  roleCheck(portals: any, roles: any) {
    portals = portals.map((item: Object) => {
      var temp = Object.assign({}, item);
      temp['openNewWindow'] = true;
      return temp;
    });

    // .filter(function (item: object) {
    //   return !item['title'].includes('Arch');
    // })

    let allRoles = Object.values(roles);

    let myPortals = portals.map((item: Object) => {
      var temp = Object.assign({}, item);

      if (this.hasFunctionalRoleAccess(temp['title'], allRoles) && this.hasEnvRoleAccess(temp['title'])) {
        temp['enabled'] = true;
      } else {
        temp['enabled'] = false;
      }
      return temp;
    });

    let isArchRole = this.authenticationService.isUserInternal();
    if (isArchRole) {
      return myPortals;
    } else {
      let myExtPortals = myPortals.filter((item: Object) => item['enabled'] != false);
      return myExtPortals;
    }
  }

  hasFunctionalRoleAccess(productName: string, allRoles: any): boolean {
    let envRoles = this.decodeToken(this.idToken);
    let hasAccess: boolean = false;

    if (envRoles) {
      let filteredRolesResult = allRoles.find((e: any) => e['portal_name'] == productName);
      let fileteredRoles = Object.keys(filteredRolesResult['role_names']);

      hasAccess = envRoles.some((r: any) => fileteredRoles.includes(r));
    }

    return hasAccess;
  }

  hasEnvRoleAccess(productName: string): boolean {
    let envRoles = this.decodeToken(this.idToken);
    let hasAccess: boolean = false;

    if (envRoles) {
      if (productName.includes('Claims')) {
        if (envRoles && envRoles.find((role: string) => role == constants.CLAIMS_ENV_ROLE + '-' + this.envName)) {
          hasAccess = true;
        }
      } else if (productName.includes('Surety')) {
        if (envRoles && envRoles.find((role: string) => role == constants.SURETY_ENV_ROLE + '-' + this.envName)) {
          hasAccess = true;
        }
      } else if (productName.includes('Professional Liability')) {
        if (envRoles && envRoles.find((role: string) => role == constants.FPL_ENV_ROLE + '-' + this.envName)) {
          hasAccess = true;
        }
      } else if (productName.includes('Small Commercial')) {
        if (envRoles && envRoles.find((role: string) => role == constants.DIGDA_ENV_ROLE + '-' + this.envName)) {
          hasAccess = true;
        }
      } else if (productName.includes('Casualty')) {
        if (envRoles && envRoles.find((role: string) => role == constants.CAS_ENV_ROLE + '-' + this.envName)) {
          hasAccess = true;
        }
      } else if (productName.includes('Programs Operations')) {
        if (envRoles && envRoles.find((role: string) => role == constants.POP_ENV_ROLE + '-' + this.envName)) {
          hasAccess = true;
        }
      } else if (productName.includes('Policy Number Generation')) {
        if (envRoles && envRoles.find((role: string) => role == constants.PONG_ENV_ROLE + '-' + this.envName)) {
          hasAccess = true;
        }
      } else if (productName.includes('Letter Of Authority')) {
        if (envRoles && envRoles.find((role: string) => role == constants.LOA_ENV_ROLE + '-' + this.envName)) {
          hasAccess = true;
        }
      } else if (productName.includes('Satellite Portal')) {
        if (envRoles && envRoles.find((role: string) => role == constants.SATELLITE_ENV_ROLE + '-' + this.envName)) {
          hasAccess = true;
        }
      } else if (productName.includes('Submission')) {
        if (envRoles && envRoles.find((role: string) => role == constants.SUBMISSION_ENV_ROLE + '-' + this.envName)) {
          hasAccess = true;
        }
      } else if (productName.includes('Healthcare Portal')) {
        if (envRoles && envRoles.find((role: string) => role == constants.HEALTHCARE_ENV_ROLE + '-' + this.envName)) {
          hasAccess = true;
        }
      } else {
        hasAccess = false;
      }
    }

    return hasAccess;
  }

  /**
   * Check user type and env flag
   * if true show access cards
   * if false then show access denied
   */
  isAllowToShowCards() {
    let envRoles = this.decodeToken(this.idToken);

    if (envRoles && envRoles.find((role: string) => role.includes(constants.ARCH))) {
      //INTERNAL USER check flag
      // console.log('ARCH');
      return environment.internalUserFlag;
    } else if (envRoles && envRoles.find((role: string) => role.includes(constants.AGENCY))) {
      //EXTERNAL USER check flag
      //console.log('AGENCY');
      return environment.externalUserFlag;
    } else {
      return false;
    }
  }

  /**
   * Check user having ENV access
   * @param void
   *
   */
  isENVValid() {
    let envRoles = this.decodeToken(this.idToken);
    var roleToCheck = constants.AIP_USER_ROLE + '-' + this.envName;
    if (envRoles && envRoles.find((role: string) => role == roleToCheck)) {
      return true;
    }
    return false;
  }
}
